// components/Navbar.js
import React, { useState } from 'react';
import logo from '../assets/logo.png';

import { Link } from 'react-router-dom';

import './Navbar.css'; // Import the CSS file

const Navbar = () => {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="maindiv">
      <nav className="navbar">
        <div className="logo">
          <img src={logo} alt="Logo" style={{ width: '200px', height: '50px' }} />
          <div className="hamburger" onClick={toggleDropdown}>
            ☰
          </div>
          {dropdownVisible && (
          <div className="dropdown">
            <a href="#mother_menu">Mother Menu</a>
            <a href="#mother_cryptocurrency">Mother Cryptocurrency</a>
          </div>
        )}
        </div>

        <div className="navbar-links">
          <a href="#wallet">Mother</a>
          <div className="navbar-links">
  <Link to="/mining">Mining</Link>
  {/* Other links... */}
</div>
        
          <a href="#wallet">Wallet</a>
          <a href="#exchange">Exchange</a>
          <a href="#defi">7DeFi</a>
          <a href="#trade">Trade</a>
          <a href="#crypto_market">Crypto Market</a>
          <a href="#nft">NFT</a>
        </div>

        <div className="auth-options">
          <a href="/">Logout</a>
        </div>

       
      </nav>

     

      {/* Add the full-width paragraph section */}
      <div className="full-width-paragraph">
        <h1>Welcome to Mother Platform</h1>
        <h2>You got welcome bonus 25$.</h2>
      </div>

     



<div className="full-width-paragraph">
        <p>Copyright © 2024 Mother Wallet & Mother Exchange in the World Setup in Dubai. Website Design & Development By Wallet Software Team in Dubai</p>  
     
      </div>




    



    </div>
  );
};

export default Navbar;
